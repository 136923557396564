<template>
  <div></div>
</template>

<script>
export default {
  created() {
    // const data = {
    //   sessionid: this.$store.state.post.sessionid,
    // };
    // this.$store.dispatch("destroySession", data).then((response) => {
    //   this.$store.commit("clearSession", null);
      // Remove Cookie
      this.$cookies.remove("smart_live_access_token");

      const token = {
        token: this.$store.state.user.token,
      };
      this.$store
        .dispatch("destroyToken", token)
        .then((err) => {
          this.$router.push("/login");
        })
        .catch((err) => {
          console.log(err.status);
        });
  //   });
  },
};
</script>

<style></style>